import { useTranslation } from "react-i18next"

const Custom404: React.FC = () => {
  const { t } = useTranslation("general")

  return (
    <div className="container p-4">
      <h1 className="mb-4 text-center text-xl md:mb-12 md:text-4xl">
        {t("404title")}
      </h1>
      <img
        className="w-full object-contain"
        src="/images/undraw_page_not_found_hiding.svg"
        alt=""
      />
    </div>
  )
}

export default Custom404
